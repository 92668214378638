import { Button as MaterialButton, ButtonProps as MaterialButtonProps, CircularProgress} from "@material-ui/core";

import classes from "./Button.module.scss";

export interface ButtonProps extends MaterialButtonProps {
  loading?: boolean;
}

const Button = ({ loading, ...props }: ButtonProps) => (
  <MaterialButton
    type="button"
    variant="contained"
    {...props}
  >
    <div className={classes.wrap}>
      <div className={loading ? classes.hidden : ''}>
        {props.children}
      </div>
      {loading && (
        <div className={classes.loading}>
          <CircularProgress color={props.color === 'primary' ? 'inherit' : 'secondary'} size={20} />
        </div>
      )}
    </div>
  </MaterialButton>
);

export default Button;