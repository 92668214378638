import { useEffect, useState, VFC } from "react";
import { toast } from "react-toastify";

import { formatErrorResponse } from "../../api";
import Button from "../Button/Button";
import { addAdminNotificationEmail } from "../../api/addAdminNotificationEmail";
import { getAdminNotificationEmails } from "../../api/getAdminNotificationEmails";
import { deleteAdminNotificationEmail } from "../../api/deleteAdminNotificationEmail";
import { CircularProgress } from "@material-ui/core";
import Form from "../hoc/Form/Form";

import classes from './ManageAdminNotificationEmails.module.scss';
import Modal from "../Modal/Modal";

type FormExt = new () => Form<{ email: string }>;
const FormComponent = Form as FormExt;

const ManageAdminNotificationEmails: VFC = () => {
  const [emailInput, setEmailInput] = useState<string>("");
  const [adminEmails, setAdminEmails] = useState<{ id: number, email: string }[]>([]);
  const [deleteModalEmail, setDeleteModalEmail] = useState<string>();
  // Loading states
  const [addLoading, setAddLoading] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<string>(); // Takes id of item being deleted
  const [getLoading, setGetLoading] = useState<boolean>(false);

  useEffect(() => {
    getEmails();
  }, [])

  const getEmails = async () => {
    setGetLoading(true);
    try {
      const emails = await getAdminNotificationEmails();
      setAdminEmails(emails.data);
    } catch (e) {
      const message = formatErrorResponse(e)
      if (message) toast.error(message);
    }
    setGetLoading(false);
  }

  const addEmail = async (email: string) => {
    setAddLoading(true);
    try {
      const { data } = await addAdminNotificationEmail(email);
      setAdminEmails([...adminEmails, data]);
      toast.success(`Succesfully added email`);
    } catch (e) {
      const message = formatErrorResponse(e)
      if (message) toast.error(message);
    }
    setAddLoading(false);
  }

  const deleteEmail = async (email: string) => {
    setDeleteLoading(email);
    try {
      await deleteAdminNotificationEmail(email);
      setAdminEmails(adminEmails.filter(value => value.email !== email));
      toast.success(`Succesfully deleted email`);
    } catch (e) {
      const message = formatErrorResponse(e)
      if (message) toast.error(message);
    }
    setDeleteModalEmail(undefined)
    setDeleteLoading(undefined);
  }

  return (
    <div className={classes.wrapper}>
      <Modal
        open={!!deleteModalEmail}
        onClose={() => setDeleteModalEmail(undefined)}
        buttons={[
          {
            color: 'primary',
            onClick: () => deleteModalEmail && deleteEmail(deleteModalEmail),
            children: 'Delete',
          },
          {
            onClick: () => setDeleteModalEmail(undefined),
            children: 'Cancel',
          },
        ]}
      >
        <>
          <h1>Are you sure you want to delete this admin email?</h1>
          <p>{deleteModalEmail} will no longer recieve usage notifications from the app.</p>
        </>
      </Modal>
      <h2>Manage Usage Notification Emails</h2>
      <div className={classes.list}>
        {getLoading ? (
          <CircularProgress color={'primary'} size={20} />
        ) : (
          <>
            {adminEmails?.map(value => (
              <div className={classes.email} key={`admin-email-${value.id}`}>
                {value.email}

                {deleteLoading === value.email ? (
                  <CircularProgress color={'primary'} size={16} />
                ) : (
                  <div className={classes.delete} onClick={() => setDeleteModalEmail(value.email)}>Delete</div>
                )}
              </div>
            ))}
          </>
        )}
      </div>
      <div className={classes.form}>
        <FormComponent
          config={{
            email: {
              required: true,
              value: emailInput,
              onChange: value => setEmailInput(value),
              inputProps: {
                placeholder: 'Email',
                type: 'email',
                name: 'email',
              },
            },
          }}
          onSubmit={vars => addEmail(vars.email)}
        >
          {({ fields, valid, triggerSubmit }) => (
            <>
              <div>{fields.email}</div>
              <Button
                type="submit"
                color="primary"
                className={classes.button}
                onClick={triggerSubmit}
                disabled={!valid || addLoading}
                loading={addLoading}
              >
                Add Email
              </Button>
            </>
          )}
        </FormComponent>
      </div>
    </div>
  );
}

export default ManageAdminNotificationEmails;