import { useState, VFC } from "react";
import { toast } from "react-toastify";

import { formatErrorResponse } from "../../api";
import { getMonthlyDataReport } from "../../api/getMonthlyDataReport";
import { jsonToCsv } from "../../utils/csvUtils";
import { convertBandwidth, DataUnit, formatBandwidth } from "../../utils/dataUtils";
import Button from "../Button/Button";
import MonthSelect from "../MonthSelect/MonthSelect";
import Select, { SelectValue } from "../Select/Select";

import classes from './MonthlyExport.module.scss';

const dataUnitOptions = [
  {
    label: DataUnit.TB,
    value: DataUnit.TB,
  },
  {
    label: DataUnit.GB,
    value: DataUnit.GB,
  },
  {
    label: DataUnit.MB,
    value: DataUnit.MB,
  },
];

const months = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];

const MonthlyExport: VFC = () => {
  const [month, setMonth] = useState<SelectValue>(new Date().getMonth());
  const [dataUnit, setDataUnit] = useState<DataUnit>(DataUnit.GB);
  const [loading, setLoading] = useState(false);

  const fetchAndDownloadReport = async () => {
    setLoading(true);
    try {
      const response = await getMonthlyDataReport(`${month}`);
      const data = response.data.map(row => {
        const bandwidth = convertBandwidth(row.totalBandwidth, 1, dataUnit);
        const bandwidthLimit = convertBandwidth(row.bandwidthLimit, 1, dataUnit);

        return {
          ...row,
          totalBandwidth: formatBandwidth(bandwidth, true, dataUnit),
          bandwidthLimit: row.bandwidthLimit ? formatBandwidth(bandwidthLimit, true, dataUnit) : '',
        }
      });

      const fileName = `${months[month]}-data-report.csv`;
      const csvData = jsonToCsv(data);

      const hiddenElement = document.createElement('a');
      hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvData);
      hiddenElement.target = '_blank';
      hiddenElement.download = fileName;
      hiddenElement.click();
    } catch (e) {
      const message = formatErrorResponse(e);
      if (message) toast.error(message);
    }
    setLoading(false);
  };

  return (
    <div className={classes.wrapper}>
      <h2>Export Data</h2>
      <div className={classes.row}>
        <div className={classes.month}>
          <MonthSelect value={month} onSelect={setMonth} />
        </div>
        <div className={classes.unit}>
          <Select
            options={dataUnitOptions}
            onSelect={value => setDataUnit(value as DataUnit)}
            value={dataUnit}
            label="Data Unit"
          />
        </div>
      </div>
      <div className={classes.button}>
        <Button color="primary" onClick={() => fetchAndDownloadReport()} loading={loading} style={{ width: '100%' }}>
          Download Report
        </Button>
      </div>
    </div>
  );
}

export default MonthlyExport;