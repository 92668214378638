import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import CenterView from '../../components/hoc/CenterView/CenterView';
import { AUTH_TOKEN_KEY } from '../../utils/constants';
import { login, LoginParameters } from '../../api/login';
import { formatErrorResponse } from '../../api';
import Form from '../../components/hoc/Form/Form';
import { InitialConfig } from '../../components/hoc/Form/FormInterfaces';

import classes from './Login.module.scss';
import Button from '../../components/Button/Button';
import { useUserContext } from '../../components/hoc/UserContext/UserContext';

type FormExt = new () => Form<LoginParameters>;
const FormComponent = Form as FormExt;

interface LoginProps {}

const initialConfig: InitialConfig = {
  email: {
    label: 'Email',
    required: true,
    inputProps: {
      type: 'email',
      name: 'email',
    },
  },
  password: {
    label: 'Password',
    required: true,
    inputProps: {
      type: 'password_any',
      name: 'password',
    },
  },
}

const Login = (props: LoginProps) => {
  const [loading, setLoading] = useState(false);
  const { getUser } = useUserContext();
  const history = useHistory();

  const loginUser = async ({ email, password }: LoginParameters) => {
    setLoading(true);
    try {
      const response = await login({
        email,
        password,
      })
      if (response && response.status === 200) {
        const token = response.data;
        localStorage.setItem(AUTH_TOKEN_KEY, token);
        getUser && getUser();
        history.push('/');
      }
    } catch (e) {
      const message = formatErrorResponse(e)
      if (message) toast.error(message);
    }
    setLoading(false);
  }

  return (
    <CenterView>
      <FormComponent
        config={initialConfig}
        onSubmit={(variables) => loginUser(variables)}
      >
        {({ fields, valid, triggerSubmit }) => (
          <div className={classes.wrap}>
            <h1 className={classes.introText}>ViewMedia Stream Manager</h1>
            {fields.email}
            {fields.password}
            <Button
              type="submit"
              color="primary"
              onClick={triggerSubmit}
              disabled={!valid || loading}
              loading={loading}
            >
              Login
            </Button>
          </div>
        )}
      </FormComponent>
    </CenterView>
  )
}

export default Login;