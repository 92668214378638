import { useState } from "react";
import { Channel } from "../../api/getChannels";
import Button from "../../components/Button/Button";
import ChannelList from "../../components/Channel/ChannelList/ChannelList";
import ChannelSingle from "../../components/Channel/ChannelSingle/ChannelSingle";
import PageHeader from "../../components/hoc/PageHeader/PageHeader";
import PageView from "../../components/hoc/PageView/PageView";
import ChannelAnalytics from "../ChannelAnalytics/ChannelAnalytics";

interface ChannelsProps {}

const Channels = (props: ChannelsProps) => {
  const [selectedChannel, setSelectedChannel] = useState<Channel | null>(null);
  const [view, setView] = useState<'edit' | 'create' | 'view' | 'list'>();

  const onReturn = () => {
    setView('list');
    setSelectedChannel(null);
  }

  const handleEditChannel = (channel: Channel) => {
    setView('edit');
    setSelectedChannel(channel);
  }

  const handleViewChannel = (channel: Channel) => {
    setView('view');
    setSelectedChannel(channel);
  }

  const handleCreateChannel = () => {
    setView('create');
    setSelectedChannel(null);
  }

  return (
    <PageView>
      {selectedChannel || view === 'create' ? (
        <>
          {view === 'view' ? (
            <ChannelAnalytics
              channel={selectedChannel}
              return={onReturn}
            />
          ) : (
            <ChannelSingle
              data={selectedChannel || undefined}
              return={onReturn}
            />
          )}
        </>
      ) : (
        <>
          <PageHeader>
            <h1>Channel Managment</h1>
            <Button onClick={handleCreateChannel}>Create</Button>
          </PageHeader>
          <ChannelList
            editChannel={handleEditChannel}
            viewChannel={handleViewChannel}
          />
        </>
      )}
    </PageView>
  )
};

export default Channels;