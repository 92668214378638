import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";

import PageHeader from "../../components/hoc/PageHeader/PageHeader";
import EmbededCode from "./components/EmbededCode/EmbededCode";
import Button from "../../components/Button/Button";
import { Channel } from "../../api/getChannels";
import { useCallback, useEffect, useMemo, useState } from "react";
import LeadStats from "./components/LeadStats/LeadStats";
import { convertBandwidth, formatBandwidth, DataUnit, formatMilliseconds } from "../../utils/dataUtils";

import classes from './ChannelAnalytics.module.scss';
import MonthSelect from "../../components/MonthSelect/MonthSelect";
import { getChannelData } from "../../api/getChannelData";

interface ChannelAnalyticsProps {
  channel: Channel | null;
  return?: () => void;
}

interface ChannelDataFormatted {
  totalBandwidth: number;
  totalRequests: number;
  requestsDaily: {
    day: number;
    requests: number;
  }[];
  bandwidthDaily: {
    day: number;
    bytes: number;
  }[];
}

const chartHeight = 400;

const colors = [
  '#8884d8',
  '#82ca9d',
  '#82ca9d',
  '#82ca9d',
  '#82ca9d',
  '#82ca9d',
  '#82ca9d',
  '#82ca9d',
  '#82ca9d',
  '#82ca9d',
];

const ChannelAnalytics = (props: ChannelAnalyticsProps) => {
  const [trafficData, setTrafficData] = useState<{ [key: string]: number }[]>();
  const [trafficGeoData, setTrafficGeoData] = useState<{ [key: string]: number | string }[]>();
  const [requestData, setRequestData] = useState<{ [key: string]: number | string }[]>();
  const [deviceData, setDeviceData] = useState<{ [key: string]: number | string }[]>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [month, setMonth] = useState(new Date().getMonth());

  const getData = useCallback(async () => {
    if (!props.channel) return;
    setLoading(true);

    try {
      const _channelData = await getChannelData(props.channel.id, month);

      if (_channelData.data) {
        setTrafficData(_channelData.data.trafficData);
        setTrafficGeoData(_channelData.data.trafficGeoData);
        setRequestData(_channelData.data.requestData);
        setDeviceData(_channelData.data.deviceData);
      }
    } catch (e) {
      setError(true)
    }

    setLoading(false);
  }, [props.channel, month]);


  useEffect(() =>  { getData() }, [getData]);

  const { totalBandwidth, totalRequests, requestsDaily, bandwidthDaily } = useMemo<ChannelDataFormatted>(() => {
    let bandwidthDaily: ChannelDataFormatted['bandwidthDaily'] = [];
    let requestsDaily: ChannelDataFormatted['requestsDaily'] = [];
    let totalBandwidth: number = 0;
    let totalRequests: number = 0;

    if (trafficData && trafficData.length)  {
      totalBandwidth = trafficData[0].outBytes;
      totalRequests = trafficData[0].outRequests;

      const data = trafficData.reduce<ChannelDataFormatted>((acc, prev) => {
        const day = new Date(prev.datetime * 1000).getDate();
        acc.totalBandwidth += prev.outBytes;
        acc.bandwidthDaily.push({
          day,
          bytes: convertBandwidth(prev.outBytes, 1),
        });
        return acc;
      }, { totalBandwidth: 0, totalRequests: 0, requestsDaily: [], bandwidthDaily: [] });

      ({ requestsDaily, bandwidthDaily, totalBandwidth, totalRequests } = data);
    }

    let lastDayOfMonth = new Date(new Date().getFullYear(), month + 1, 0);
    for (let i = 1; i <= lastDayOfMonth.getDate(); i++) {
      let bytes = 0;
      const bandwidthDataIndex = bandwidthDaily?.findIndex(v => v.day === i);
      if (bandwidthDataIndex !== -1) {
        bytes = bandwidthDaily[bandwidthDataIndex].bytes;
        bandwidthDaily = bandwidthDaily.filter(v => v.day !== i);
      }
      bandwidthDaily.push({
        day: i,
        bytes,
      });

      if (requestData && requestData.length) {
        const dayRequestData = requestData[i - 1];

        if (dayRequestData) {
          const requests = Number(dayRequestData.uniqueRequests);
          totalRequests += requests;
          requestsDaily.push({
            day: i,
            requests,
          });
        }
      }
    }

    return { totalBandwidth, totalRequests, requestsDaily, bandwidthDaily };
  }, [trafficData, month, requestData]);

  const dateHeading = useMemo(() => {
    const date = new Date(new Date().getFullYear(), month);

    return `- ${date.toLocaleString('default', { month: 'long' })}, ${date.getFullYear()}`;
  }, [month]);

  if (!props.channel) {
    return (
      <div className={classes.error}>No channel selected</div>
      );
  }

  return (
    <>
      {props.return && (
        <PageHeader>
          <h1>{props.channel.channelName}</h1>
          <div>
            <Button onClick={() => props.return && props.return()}>
              Return
            </Button>
          </div>
        </PageHeader>
      )}
      <EmbededCode
        channelId={props.channel.channelId}
      />
      {error && (
        <div className={classes.error}>Unable to connect to data segment. Please check the channel id.</div>
      )}

      {!loading  && !error && (
        <>
          <h2 className={classes.heading}>Stats {dateHeading}</h2>
          <MonthSelect value={month} onSelect={value => setMonth(Number(value))} />
          <LeadStats
            totalBandwidth={totalBandwidth}
            totalRequests={totalRequests}
          />
          <h2 className={classes.heading}>Bandwidth {dateHeading}</h2>
          <div className={classes.chart}>
            <ResponsiveContainer width="100%" height={chartHeight}>
              <LineChart margin={{ right: 30 }} data={bandwidthDaily} >
                <CartesianGrid strokeDasharray="1 1" />
                <XAxis tickMargin={10} interval={1} dataKey="day" />
                <YAxis />
                <Tooltip />
                <Line name="Total Bandwidth (GB)" type="monotone" dataKey={'bytes'} stroke={colors[0]} />
              </LineChart>
            </ResponsiveContainer>
          </div>
          <h2 className={classes.heading}>Unique Requests {dateHeading}</h2>
          <div className={classes.chart}>
            <ResponsiveContainer width="100%" height={chartHeight}>
              <LineChart margin={{ right: 30 }} data={requestsDaily}>
                <CartesianGrid strokeDasharray="1 1" />
                <XAxis tickMargin={10} interval={1} dataKey="day" />
                <YAxis />
                <Tooltip />
                <Line name="Total Requests" type="monotone" dataKey={'requests'} stroke={colors[0]} />
              </LineChart>
            </ResponsiveContainer>
          </div>
          <h2 className={classes.heading}>Countries {dateHeading}</h2>
          <div className={classes.table}>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Country</TableCell>
                    <TableCell align="right">Unique Requests</TableCell>
                    <TableCell align="right">Bandwidth</TableCell>
                    <TableCell align="right">AVG Duration</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {trafficGeoData?.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell component="th" scope="row">{row.name}</TableCell>
                      <TableCell align="right">{row.outRequests ? row.outRequests : 0}</TableCell>
                      <TableCell align="right">{formatBandwidth(convertBandwidth(Number(row.outBytes), 2, DataUnit.GB), true, DataUnit.GB)}</TableCell>
                      <TableCell align="right">{`${Math.round(Number(row.duration) / 60000)}m`}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <h2 className={classes.heading}>Devices {dateHeading}</h2>
          <div className={classes.table}>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Device</TableCell>
                    <TableCell align="right">Requests</TableCell>
                    <TableCell align="right">Duration</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {deviceData?.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell component="th" scope="row">{row.agent}</TableCell>
                      <TableCell align="right">{row.requests ? row.requests : 0}</TableCell>
                      <TableCell align="right">{formatMilliseconds(Number(row.duration))}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </>
      )}
      {loading && !error && (
        <div className={classes.loading} style={{ height: chartHeight }}>
          <CircularProgress />
        </div>
      )}
    </>
  );
}

export default ChannelAnalytics;