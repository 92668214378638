import axios from 'axios';
import { toast } from 'react-toastify';
import { history } from '..';
import { AUTH_TOKEN_KEY } from "../utils/constants";

export const formatErrorResponse = (e: any) => {
  if (e.response && e.response.data) {

    if (typeof e.response.data === 'string') {
      return e.response.data;
    }

    // Joi validation error
    if (e.response.data.details && e.response.data.details.body.length) {
      return e.response.data.details.body[0].message;
    }
  }

  return e.message;
}

const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  params: {},
  withCredentials: true,
});

API.interceptors.request.use(request => {
  const storageToken = localStorage.getItem(AUTH_TOKEN_KEY);

  if (storageToken) {
    request.headers["Authorization"] =  `Bearer ${storageToken}`;
  }

  return request;
});

API.interceptors.response.use(response => response, (error) => {
  if (error.message === 'Network Error') {
    history.push('/login');
    toast.error('Network Error - Please ensure that the API is running')
    return;
  }

  if (error.response && error.response.status === 401) {
    localStorage.removeItem(AUTH_TOKEN_KEY);
    history.push('/login');
  }

  return Promise.reject(error);
});

export default API;