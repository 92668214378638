import { convertBandwidth, DataUnit } from '../../../../utils/dataUtils';
import classes from './LeadStats.module.scss';

export interface LeadStatsProps {
  totalRequests: number;
  totalBandwidth: number;
}

const formatTotalBandwidth = (input: number) => {
  if (input > 1000000000000) {
    return `${convertBandwidth(input, 1, DataUnit.TB)}TB`;
  } else {
    return `${convertBandwidth(input, 1, DataUnit.GB)}GB`;
  }
}

const LeadStats = (props: LeadStatsProps) => (
  <div className={classes.wrapper}>
    <div className={classes.stat}>
      <h2>Total Unique Requests</h2>
      <h1>{props.totalRequests}</h1>
    </div>
    <div className={classes.stat}>
      <h2>Total Bandwidth</h2>
      <h1>{formatTotalBandwidth(props.totalBandwidth)}</h1>
    </div>
  </div>
);


export default LeadStats;