import { parseCamelCase } from "./stringUtils";

export const jsonToCsv = <T>(data: T[], delimiter = ',', includeHeaders = true) => {
  let csv = '';
  const headers = Object.keys(data[0] || [])

  if (includeHeaders) {
    const parsedHeaders = headers.map(header => parseCamelCase(header));
    csv += `${parsedHeaders.join(delimiter)}\n`;
  }

  for (const item of data) {
    let dataRow = '';
    const dataLength = headers.length;

    for (let i = 0; i < dataLength; i++) {
      const key = headers[i];
      dataRow += item[key];

      // Add delimiter in-between all values apart last value on a row
      if ((i + 1) !== dataLength) {
        dataRow += delimiter;
      }
    }

    csv += `${dataRow}\n`;
  }

  return csv;
}

