import { VFC } from "react";

import Select, { SelectOption, SelectValue } from "../Select/Select";

interface Props {
  onSelect: (month: SelectValue) => void;
  value: SelectValue;
}

export const monthOptions: SelectOption[] = [
  {
    label: 'January',
    value: 0,
  },
  {
    label: 'February',
    value: 1,
  },
  {
    label: 'March',
    value: 2,
  },
  {
    label: 'April',
    value: 3,
  },
  {
    label: 'May',
    value: 4,
  },
  {
    label: 'June',
    value: 5,
  },
  {
    label: 'July',
    value: 6,
  },
  {
    label: 'August',
    value: 7,
  },
  {
    label: 'September',
    value: 8,
  },
  {
    label: 'October',
    value: 9,
  },
  {
    label: 'November',
    value: 10,
  },
  {
    label: 'December',
    value: 11,
  },
];

// Orders month select options to start from the current month
const orderMonthOtions = (months: SelectOption[]): SelectOption[] => {
  const currentYearMonthOptions: SelectOption[] = [];
  const previousYearMonthOptions: SelectOption[] = [];
  const month = new Date().getMonth();

  for (let i = 0; i < months.length; i++) {
    if (months[i].value > month) {
      previousYearMonthOptions.push(months[i]);
    } else {
      currentYearMonthOptions.push(months[i]);
    }
  }

  return [
    ...previousYearMonthOptions,
    ...currentYearMonthOptions,
  ];
}
const MonthSelect: VFC<Props> = ({ onSelect, value }) => (
  <Select
    label="Month"
    onSelect={onSelect}
    value={value}
    options={orderMonthOtions(monthOptions)}
  />
)
export default MonthSelect;