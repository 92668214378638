import { useState } from "react";
import { CircularProgress } from "@material-ui/core";

import { User } from "../../../../../api/getChannelUsers";
import { Role } from "../../../../../api/getSelf";
import Button from "../../../../Button/Button";
import UserForm from "./components/UserForm/UserForm";
import Modal from "../../../../Modal/Modal";

import classes from "./ChannelUsers.module.scss";
import { toast } from "react-toastify";

interface ChannelUserViewProps {
  data: User[];
  channelId: string;
  onDelete: (id: string) => void;
  deleteLoading: string | undefined;
  updateUsers: (users: User[]) => void;
  loading: boolean;
}

const sortListByEmail = (list: User[]) => list.sort((a, b) => (a.email > b.email) ? 1 : -1)

const ChannelUserView = (props: ChannelUserViewProps) => {
  const [modal, setModal] = useState<boolean | User>(false);

  const onError = (e) => {
    setModal(false);
  }

  const onCompleted = (email: string, oldEmail?: string) => {
    // Update
    if (typeof modal === 'object') {
      const updatedUser = props.data.find(user => user.email === oldEmail);
      if (updatedUser) {
        const updatedUsers = [
          {
            ...updatedUser,
            email,
          },
          ...props.data.filter(user => user.email !== oldEmail),
        ];
        props.updateUsers(sortListByEmail(updatedUsers));
        toast.success('Succesfully updated user');
      }
    } else {
      const updatedUsers = [
        ...props.data,
        {
          id: '',
          email,
          role: 'channel-owner' as Role,
        },
      ]
      props.updateUsers(sortListByEmail(updatedUsers));
      toast.success('Succesfully created user');
    }

    setModal(false);
  }

  return (
    <>
      <Modal
        open={!!modal}
        onClose={() => setModal(false)}
      >
        <UserForm
          data={typeof modal === 'object' ? modal : undefined}
          onComplete={onCompleted}
          onError={onError}
          channelId={props.channelId}
        />
      </Modal>
      <div className={classes.wrap}>
        <h2>Manage Users</h2>
        {props.loading ? (
          <div className={classes.loading}>
            <CircularProgress />
          </div>
        ) : (
          <div className={classes.userList}>
            {props.data.map(user => (
              <div className={classes.user} key={`channel-users-${user.id}`}>
                <div className={classes.email}>{user.email}</div>

                  {props.deleteLoading === user.id ? (
                    <CircularProgress color={'primary'} size={16} />
                  ) : (
                    <div className={classes.action}>
                      <div onClick={() => setModal(user)}>
                        Edit
                      </div>
                      <div onClick={() => props.onDelete(user.id)}>
                        Delete
                      </div>
                    </div>
                  )}

              </div>
            ))}
            <div className={classes.add}>
              <Button
                onClick={() => setModal(true)}
                color="primary"
              >
                Add
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default ChannelUserView;