export enum DataUnit {
  MB = 'MB',
  GB = 'GB',
  TB = 'TB',
}

export const dataConversionMultipliers: { [key in DataUnit]: number } = {
  'TB': 1000000000000,
  'GB': 1000000000,
  'MB': 1000000,
}

export const convertBandwidth = (bytes: number, round?: number, format: DataUnit = DataUnit.GB) => {
  const output = bytes / dataConversionMultipliers[format];

  if (round) {
    const factorOfTen = Math.pow(10, round);
    return Math.round(output * factorOfTen) / factorOfTen;
  }

  return output;
}

export const formatBandwidth = (gb: number, round = false, format: DataUnit = DataUnit.GB) => `${(round ? Math.round(gb * 1000) / 1000 : gb)} ${format}`;

export const formatMilliseconds = (input: number) => {
  let minutes = Math.floor(input / 60000);
  let seconds = Number(((input % 60000) / 1000).toFixed(0));

  minutes = seconds === 60 ? minutes + 1 : minutes;
  seconds = seconds === 60 ? 0 : seconds;

  if (minutes === 0 && seconds === 0 && input > 0) {
    return '< 1s'
  }

  if (minutes === 0 && seconds === 0) {
    return '0s';
  }

  if (minutes === 0 && seconds !== 0) {
    return `${seconds}s`
  }

  return `${minutes}m ${seconds}s`;
}
