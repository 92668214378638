import { TextField } from "@material-ui/core";

import classes from './EmbededCode.module.scss';

interface EmbededCodeProps {
  channelId: string | undefined;
}

const EmbededCode = (props: EmbededCodeProps) => (
  <div className={classes.wrap}>
    <p><strong>Multi-Device Iframe Website Embed Code:</strong></p>
    <p>Copy & paste below to your website.</p>
    <p><strong>Note:</strong> You can customize iframe width/height parameters based on your website design.</p>
    <TextField
      value={`<iframe src="https://iframe.viewmedia.tv?channel=${props.channelId}" width="640" height="360" frameborder="0" allowfullscreen webkitallowfullscreen mozallowfullscreen></iframe>`}
      multiline
      variant="filled"
      rows={4}
      className={classes.iframeField}
    />
  </div>
)


export default EmbededCode;