import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { formatErrorResponse } from "../../../../../api";
import { deleteUser } from "../../../../../api/deleteUser";
import { getChannelUsers, User } from "../../../../../api/getChannelUsers";
import ChannelUserView from "./ChannelUsersView";

interface ChannelUsersProps {
  channelId: string;
}

const ChannelUsers = (props: ChannelUsersProps) => {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<string | undefined>();

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const response = await getChannelUsers(props.channelId);
        if (response && response.status === 200) {
          setUsers(response.data);
        }
      } catch (e) {
        const message = formatErrorResponse(e);
        if (message) toast.error(message);
      }
      setLoading(false);
    }
    fetchUsers();
  }, [props.channelId]);

  const onDelete = async (id: string) => {
    setDeleteLoading(id);
    try {
      const response = await deleteUser(id);
      if (response && response.status === 200) {
        // Remove user from state without triggering refetch
        setUsers(users.filter(user => user.id !== id));
        toast.success('Succesfully deleted user');
      }
    } catch (e) {
      const message = formatErrorResponse(e);
      if (message) toast.error(message);
    }
    setDeleteLoading(undefined);
  }

  return (
    <ChannelUserView
      data={users}
      onDelete={onDelete}
      deleteLoading={deleteLoading}
      updateUsers={setUsers}
      channelId={props.channelId}
      loading={loading}
    />
  );
}

export default ChannelUsers;