import { useState } from "react";
import { Channel } from "../../api/getChannels";
import ChannelSingle from "../../components/Channel/ChannelSingle/ChannelSingle";
import PageHeader from "../../components/hoc/PageHeader/PageHeader";
import PageView from "../../components/hoc/PageView/PageView";
import { useUserContext } from "../../components/hoc/UserContext/UserContext";

interface ChannelUserProps {}

const ChannelUser = (props: ChannelUserProps) => {
  const { user } = useUserContext();
  const [selectedChannel] = useState<Channel | undefined>(user && user.channels.length ? user.channels[0] : undefined);

  return (
    <PageView>
      {!user?.channels.length ? (
        <h1>No channels found</h1>
      ) : (
        <>
          <PageHeader>

          </PageHeader>
          <ChannelSingle
            data={selectedChannel}
          />
        </>
      )}
    </PageView>
  )
};

export default ChannelUser;