import { VFC } from "react";
import { FormControl, InputLabel, MenuItem, Select as MaterialSelect } from "@material-ui/core";

import classes from './Select.module.scss';

export type SelectValue = string | number;

export interface SelectOption {
  label: string;
  value: SelectValue;
}

interface Props {
  onSelect: (value: SelectValue) => void;
  value: SelectValue;
  options: SelectOption[];
  label?: string;
}

const Select: VFC<Props> = ({ onSelect, value, options, label }) => {
  return (
    <FormControl variant="filled" className={classes.select}>
      {label && <InputLabel>{label}</InputLabel>}
      <MaterialSelect onChange={(event) => onSelect(event.target.value as number)} labelId="label" id="select" value={value}>
        {options.map(option => (
          <MenuItem key={`option.${option.value}`} value={option.value}>{option.label}</MenuItem>
        ))}
      </MaterialSelect>
    </FormControl>
  );
}

export default Select;