import { useEffect, useState } from "react";

import { Channel } from "../../../api/getChannels";
import Button from "../../Button/Button";
import PageHeader from "../../hoc/PageHeader/PageHeader";
import { useUserContext } from "../../hoc/UserContext/UserContext";
import ChannelForm from "./components/ChannelForm/ChannelForm";
import ChannelUsers from "./components/ChannelUsers/ChannelUsers";

interface ChannelSingleProps {
  data?: Channel;
  return?: () => void;
}

const ChannelSingle = (props: ChannelSingleProps) => {
  const [channel, setChannel] = useState(props.data);
  const { user } = useUserContext();

  useEffect(() => {
    setChannel(props.data);
  }, [props.data]);

  return (
    <div>
      {props.return && (
        <PageHeader>
          <h1>Channel Details</h1>
          <div>
            <Button onClick={() => props.return && props.return()}>
              Return
            </Button>
          </div>
        </PageHeader>
      )}
      <ChannelForm
        data={channel}
        updateChannel={setChannel}
      />
      {(user && user.role === 'admin' && channel) && (
        <ChannelUsers
          channelId={channel.id}
        />
      )}
    </div>
  )
}

export default ChannelSingle;