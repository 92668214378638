import API from "..";
import { PaginatedParameters, PaginatedResponseData } from "../types";

export interface Channel {
  id: string;
  channelId: string;
  channelName: string;
  bandwidthLimit?: number;
}

export const getChannels = async (params?: PaginatedParameters) => API.get<PaginatedResponseData<Channel>>('/channel', { params });